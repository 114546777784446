import { createSlice } from "@reduxjs/toolkit";

const serviceSlice = createSlice({
    name: "service",
    initialState: {
        allServices : []
    },
    reducers: {
        getServices: (state, action) => {
            state.allServices = action.payload;
        },
    }
});

export const { getServices } = serviceSlice.actions;
export default serviceSlice.reducer;
