import React, { useState } from "react";
import "./CreateService.css";
import { publicRequest } from "../../requestMethods";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function CreateService() {
  const allServices = useSelector(state=>state.service.allServices);
  const location = useLocation();
  let serv = "";
  let desc = "";
  let img = "";
  let cat = [{ name: "", price: "", detail: [{id:"",detailName:"",option:[{optName:""}]}] }];
  const editService = location.state;
  if(editService==="edit"||editService==="view"){
    const url = location.pathname.split('/');
    const data = allServices.find((service)=>service._id===url[2]);
    img = data.image;
    serv = data.service;
    desc = data.desc;
    cat = data.categories;
  }
  
  const [service,setservice] = useState(serv);
  const [description,setDescription] = useState(desc);
  const [categories, setCategories] = useState(cat);
  const [image, setImage] = useState(img);

  const handleCatAddition = () => {
    setCategories((prevCategories) => [
      ...prevCategories,
      { name: "", price: "", detail: [] },
    ]);
  };

  const handleDetAddition = (index) => {
    setCategories((prevCategories) =>
      prevCategories.map((category, i) =>
        i === index
          ? { ...category, detail: [...category.detail, {id:"", detailName: "", option: [] }] }
          : category
      )
    );
  };

  const handleoptAddition = (index, detIndex) => {
    setCategories((prevCategories) =>
      prevCategories.map((category, i) =>
        i === index
          ? {
              ...category,
              detail: category.detail.map((det, j) =>
                j === detIndex
                  ? {
                      ...det,
                      option: [...det.option, { optName: "" }],
                    }
                  : det
              ),
            }
          : category
      )
    );
  };

  const handleCatChange = (e, index) => {
    const { value } = e.target;
    setCategories((prevCategories) =>
      prevCategories.map((category, i) => (i === index ? { ...category, name: value } : category))
    );
  };

  const handlePriceChange = (e, index) => {
    const { value } = e.target;
    setCategories((prevCategories) =>
      prevCategories.map((category, i) => (i === index ? { ...category, price: value } : category))
    );
  };

  const handleDetNameChange = (e, index, detIndex) => {
    const { value } = e.target;
    setCategories((prevCategories) =>
      prevCategories.map((category, i) =>
        i === index
          ? {
              ...category,
              detail: category.detail.map((det, j) =>
                j === detIndex ? { ...det, detailName: value } : det
              ),
            }
          : category
      )
    );
  };
  const handleDetIdChange = (e, index, detIndex) => {
    const { value } = e.target;
    setCategories((prevCategories) =>
      prevCategories.map((category, i) =>
        i === index
          ? {
              ...category,
              detail: category.detail.map((det, j) =>
                j === detIndex ? { ...det, id: value } : det
              ),
            }
          : category
      )
    );
  };

  const handleOptNameChange = (e, index, detIndex, optIndex) => {
    const { value } = e.target;
    setCategories((prevCategories) =>
      prevCategories.map((category, i) =>
        i === index
          ? {
              ...category,
              detail: category.detail.map((det, j) =>
                j === detIndex
                  ? {
                      ...det,
                      option: det.option.map((opt, k) =>
                        k === optIndex ? { ...opt, optName: value } : opt
                      ),
                    }
                  : det
              ),
            }
          : category
      )
    );
  };
  const Navigate = useNavigate();
  const handleEditService = async()=>{
    const url = location.pathname.split('/');
    const data = {
      service : service,
      image : image,
      desc : description,
      categories:categories,
    };
    try {
      const res = await publicRequest.put(`/service/${url[2]}`, data);
      if(res.data){
        toast.success("updated Successfully");
        Navigate('/services/all');
      }
    } catch (error) {
      // Handle error here
    }

  }
  const handleCreateService = async () => {

    const data = {
      service: service,
      image : image,
      desc: description,
      categories: categories,
    };
    try {
      const res = await publicRequest.post('/service/new', data);
      if(res.data){
        toast.success("Service Created Successfully");
        Navigate('/services/all');
      }
    } catch (error) {
      // Handle error here
    }
  };

  return (
    <div className="create-service-main">
      <div className="title-div">
        <h1 className="heading">Add Service</h1>
        <div className="sec-1">
          {editService==="view"?<h2>{service}</h2>:<input value={service} className="input-style" type="text" placeholder="Service Name" onChange={(e)=>setservice(e.target.value)} />}
          {editService==="view"?<h2>{image}</h2>:<input value={image} className="input-style" type="text" placeholder="Image Url" onChange={(e)=>setImage(e.target.value)} />}
          {editService==="view"?<p>{description}</p>:<textarea className="text-area" value={description} placeholder="Description" onChange={(e)=>setDescription(e.target.value)}></textarea>}
        </div>
      </div>
      <div className="title-div">
        <h1 className="heading">Categories</h1>
        {categories.map((category, index) => (
          <div className="sec-2" key={index}>
            {editService==="view"?<p>{category.name}</p>:<input
              className="cat-input-style"
              type="text"
              placeholder="Category Name"
              value={category.name}
              onChange={(e) => handleCatChange(e, index)}
            />}
            {editService==="view"? <p>{category.price}</p> :<input
              type="number"
              className="cat-input-style"
              placeholder="Set Base Price"
              value={category.price}
              onChange={(e) => handlePriceChange(e, index)}
            />}
            {category.detail &&
              category.detail.map((det, detIndex) => (
                <div className="sec-3" key={detIndex}>
                  <div className="detail-input-warpper">
                    {editService==="view"? <p>{det.detailName}</p> :<input
                      className="detail-input-style"
                      type="text"
                      placeholder={`Detail Name`}
                      value={det.detailName}
                      onChange={(e) => handleDetNameChange(e, index, detIndex)}
                    />}
                    {editService==="view"? <p>{det.id}</p> :<input
                      className="detail-input-style"
                      type="text"
                      placeholder={`Detail Id`}
                      value={det.id}
                      onChange={(e) => handleDetIdChange(e, index, detIndex)}
                    />}
                    {editService!=="view" && <button
                      className="opt-btn"
                      onClick={() => handleoptAddition(index, detIndex)}
                    >
                      Add Opt
                    </button>}
                  </div>
                  <div className="option-input-wrapper">
                    {det.option &&
                      det.option.map((opt, optIndex) => (
                        editService==="view"?<p>{opt.optName}</p>:<input
                          key={optIndex}
                          className="opt-input-style"
                          type="text"
                          placeholder="Name : Option Price"
                          value={opt.optName}
                          onChange={(e) =>
                            handleOptNameChange(e, index, detIndex, optIndex)
                          }
                        />
                      ))}
                  </div>
                </div>
              ))}
            <div className="btn-div">
              <button className="det-btn" onClick={() => handleDetAddition(index)}>
                <h5>Add New Detail</h5>
              </button>
            </div>
          </div>
        ))}
        <div className="btn-div">
          <button className="cat-btn" onClick={handleCatAddition}>
            <h5>Add New Category</h5>
          </button>
        </div>
      </div>
      {editService==="edit" ?
      <div className="create-btn-wrapper">
        <button className="create-btn" onClick={()=>handleEditService()}>Save Changes</button>
      </div> :
      <div className="create-btn-wrapper">
        <button className="create-btn" onClick={()=>handleCreateService()}>Create Service</button>
      </div>}
    </div>
  );
}

export default CreateService;
