import { configureStore, combineReducers } from '@reduxjs/toolkit';
import userReducer from './userRedux';
import bookingReducer from './bookingRedux';
import statsReducer from './statsRedux'
import productReducer from "./productRedux"
import serviceReducer from "./serviceRedux"
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
  } from "redux-persist";
  import storage from "redux-persist/lib/storage";

const persistConfig = {
    key: "root",
    version: 1,
    storage,
};

const rootReducer = combineReducers({
  user: userReducer,
  service : serviceReducer,
  booking : bookingReducer,
  stat : statsReducer
});

const persistedReducer = persistReducer(persistConfig,rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export let persistor =  persistStore(store);
