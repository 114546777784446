import { loginFailure, loginStart, loginSuccess } from "./userRedux";
import { getProductStart,getProductSuccess,getProductFailure } from "./productRedux";
import { publicRequest, userRequest } from "../requestMethods";
import { getServices } from "./serviceRedux";
import { getBookingFailure, getBookingStart, getBookingSuccess } from "./bookingRedux";


export const login = async (dispatch, user) => {
  dispatch(loginStart());
  try {
    const res = await publicRequest.post("/auth/login", user);
    dispatch(loginSuccess(res.data));
  } catch (err) {
    dispatch(loginFailure());
  }
};

export const getProduct = async (dispatch)=>{
  dispatch(getProductStart());
  try{
    const res = await publicRequest.get('/product');
    dispatch(getProductSuccess(res.data));
  }catch(error){
    dispatch(getProductFailure());
  }
}

export const getAllServices = async(dispatch)=>{
  try{
    const res = await publicRequest.get('/service?admin=true');
    dispatch(getServices(res.data));
  }catch(err){

  }
}

export const getAllBookings = async(dispatch)=>{
  dispatch(getBookingStart());
  try{
    const res = await userRequest.get('/bookings/all');
    dispatch(getBookingSuccess(res.data));
  }
  catch(error){
    dispatch(getBookingFailure());
  }
}
