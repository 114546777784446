import React, { useEffect } from 'react';
import './productList.css';
import { Link, useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import { useDispatch, useSelector } from 'react-redux';
import { getAllServices } from '../../redux/apiCalls';
import { publicRequest } from '../../requestMethods';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ProductList() {
  const allServices = useSelector((state) => state.service.allServices);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    getAllServices(dispatch);
  }, [dispatch]);

  const handleServiceEdit = (sid) => {
    navigate(`/services/${sid}`, { state: 'edit' });
  };

  const handleServiceView = (sid) => {
    navigate(`/services/${sid}`, { state: 'view' });
  };

  const handleDeleteService = (sid) => {
    toast.warn(
      <div className='delete-pop-up'>
        <span>Are you sure you want to delete this service?</span>
        <div className='delete-buttons'>
          <DoneIcon onClick={() => handleDeleteConfirmed(sid)} className='pop-up-icons' />
          <ClearIcon onClick={() => toast.dismiss()} className='pop-up-icons' />
        </div>
      </div>,
      {
        autoClose: false,
        draggable: true,
        closeButton: false,
      }
    );
  };

  const handleDeleteConfirmed = async (sid) => {
    try {
      await publicRequest.delete(`/service/${sid}`);
      toast.dismiss();
      toast.success('Service deleted successfully');
      getAllServices(dispatch); // Refresh the list of services
    } catch (error) {
      console.error('Error deleting service:', error);
      toast.error('Failed to delete service');
    }
  };

  const columns = [
    { field: '_id', headerName: 'ID', width: 220 },
    {
      field: 'service',
      headerName: 'Service',
      width: 200,
      renderCell: (params) => {
        return <div className='productListItem'>{params.row.service}</div>;
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 200,
      renderCell: (params) => {
        return (
          <div className='action-tabs'>
            <button onClick={() => handleServiceView(params.row._id)} className='productListView'>
              View
            </button>
            <button onClick={() => handleServiceEdit(params.row._id)} className='productListEdit'>
              Edit
            </button>
            <DeleteOutlineIcon
              onClick={() => handleDeleteService(params.row._id)}
              className='productListDelete'
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className='productList'>
        <DataGrid
          rows={allServices}
          disableSelectionOnClick
          columns={columns}
          getRowId={(row) => row._id}
          pageSize={8}
          checkboxSelection
        />
      </div>
      <Link to='/services/new'>
        <div className='add-service'>
          <h3>Add New Service</h3>
        </div>
      </Link>
    </>
  );
}

export default ProductList;
