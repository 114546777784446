import { createSlice } from "@reduxjs/toolkit";

const statsSlice = createSlice({
    name: "stat",
    initialState: {
        userStats : []
    },
    reducers: {
        getUserStats: (state, action) => {
            state.userStats = action.payload;
        },
    }
});

export const { getUserStats } = statsSlice.actions;
export default statsSlice.reducer;