import React,{useEffect,useState} from 'react'
import './transactions.css'
import { Link } from 'react-router-dom';
import { userRequest } from '../../requestMethods';
import { useDispatch, useSelector } from 'react-redux';
import { getAllBookings } from '../../redux/apiCalls';
import {format} from 'date-fns'

function Transactions() {
    const orders = useSelector(state=>state.booking.bookings);
    const dispatch = useDispatch();
    const Button = ({ type }) => {
        return <button className={"widgetLgButton " + type}>{type}</button>;
      };
      // const [orders,setOrders] = useState([]);
      useEffect(()=>{
        getAllBookings(dispatch);
      },[dispatch]);

      const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return format(date, 'MMMM d, yyyy');
      };

  return (
    <div className='trans-container'>
    <div className="widgetLg">
      <table className="widgetLgTable">
        <tr className="widgetLgTr">
          <th className="widgetLgTh">Booking Id</th>
          <th className="widgetLgTh">Transaction Id</th>
          <th className="widgetLgTh">Services</th>
          <th className="widgetLgTh">Amount</th>
          <th className="widgetLgTh">Created At</th>
          <th className="widgetLgTh">Status</th>
        </tr>
        {orders?.map(user=>(
          <tr className="widgetLgTr">
          <td className="widgetLgUser">
            <img
              src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png"
              alt=""
              className="widgetLgImg"
            />
            <span className="widgetLgName">{user._id}</span>
          </td>
          <td className="widgetLgDate">{user?.onTrail ? 'onTrail' : user.transactionId} </td>
          <td className="widgetLgAmount">
          <Link to={`/transactions/${user._id}`}>Service</Link>
          </td>
          <td className="widgetLgAmount">₹{user.totalPrice}</td>
          <td className="widgetLgName">
            {formatDate(user.createdAt)}
          </td>
          <td className="widgetLgStatus">
            <Button type={user?.bookingStatus} />
          </td>

        </tr>
        ))}
      </table>
    </div>
    </div>
  )
}

export default Transactions
