import React, { useEffect, useState } from 'react';
import './transacDetails.css';
import CircleIcon from '@mui/icons-material/Circle';
import { userRequest } from '../../requestMethods';
import { useLocation,useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function TransacDetails() {
  const bookings = useSelector(state=>state.booking.bookings);
  // const [booking, setBooking] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname.split('/');
  const tid = path[2];
  const booking = bookings?.find((bk)=>bk._id===tid);
  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return format(date, 'MMMM d, yyyy');
  };

  // useEffect(() => {
  //   const getBooking = async () => {
  //     const res = await userRequest.get(`/bookings/${tid}`);
  //     setBooking(res.data);
  //   };
  //   getBooking();
  // }, [tid]);

  useEffect(() => {
    console.log(booking);
  }, [booking]);

  const handleStatusUpdate = async(status)=>{
    try{
      const res = await userRequest.patch(`/bookings/${tid}`,{status:status});
      if(res.data){
        toast.success(`Booking ${status}`);
        navigate('/transactions/all');
      }
    }catch(error){
      toast.warn('Internal Server Error');
      console.log(error);
    }
    
  }

  return (
    <div className='container'>
      <div className="wrapper">
        <div className="ids-wrapper">
          <div className="ids">
            <div className="id">
              <h3>Booking Id :</h3>
              <p>{booking?._id}</p>
            </div>
            <div className="id">
              <h3>User Id :</h3>
              <p>{booking?.userId}</p>
            </div>
            <div className="id">
              <h3>Transaction Id :</h3>
              <p>{booking?.onTrail ?'onTrail':booking?.transactionId}</p>
            </div>
            <div className="id">
              <h3>Service Timing :</h3>
              <p>{booking?.serviceTiming}</p>
            </div>
            <div className="id">
              <h3>Starting Date:</h3>
              <p>{formatDate(booking?.startDate)}</p>
            </div>
            <div className="id">
              <h3>Address:</h3>
              <p>{`${booking?.selectedAddress.address}`}{`  ,${booking?.selectedAddress.city}`}{` ,${booking?.selectedAddress.pinCode}`}</p>
            </div>
            <div className="id">
              <h3>Status:</h3>
              <p>{`${booking?.bookingStatus}`}</p>
            </div>
          </div>
        </div>
        <div className="service-wrapper">
          {/* service container */}
          {booking?.services?.map((category, index) => (
            <div className="service-container" key={index}>
              <div className="pin"></div>
              <div className="service-name">
                <h3>Service :</h3>
                <p>{category?.categoryName}</p>
              </div>
              <div className="details">
                <div className="detail-name"><h3>Details :</h3></div>
                {category?.optionsData && Object.entries(category.optionsData).map(([key, value], ind) => (
                  <div className="det" key={ind}>
                    <p className='ques'>{key}</p>
                    <p className='ans'><CircleIcon style={{ fontSize: "8px" }} />{value.optionName}</p>
                    {/* <p className='ans'><CircleIcon style={{ fontSize: "8px" }} />{value.optionPrice}</p> */}
                  </div>
                ))}
              </div>
            </div>
          ))}
          {/* service container */}
        </div>
        <div className="action-buttons">
          <button className='approve' onClick={()=>{handleStatusUpdate('approved')}}>Approve</button>
          <button className='decline' onClick={()=>{handleStatusUpdate('declined')}}>Decline</button>
          {/* <button>Approved</button> */}
        </div>
      </div>

    </div>
  );
}

export default TransacDetails;
